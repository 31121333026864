import Vue from 'vue';

const EventBus = new Vue();

// Product Dialog Editing
const FOLLOWED_PRODUCT_IN_CURR_COLL = 'followedProductInCurrColl';
const DUPLICATED_PRODUCT_IN_CURR_COLL = 'duplicatedProductInCurrCol';
const EDITED_FOLLOWED_PRODUCT = 'editedFollowedProd';
const SCRAPE_PRODUCT_DATA = 'scrapeProductData';

// Collection
const CREATE_COLLECTION = 'createCollection';

// Searching
const START_NEW_COMMUNITY_SEARCH = 'startNewCommunitySearch';

// Schedule Attachment/Image Cell Syncing
const ATTACHMENT_CELL_UPDATED = 'attachmentCellUpdated';

export {
  EventBus,
  FOLLOWED_PRODUCT_IN_CURR_COLL,
  DUPLICATED_PRODUCT_IN_CURR_COLL,
  EDITED_FOLLOWED_PRODUCT,
  SCRAPE_PRODUCT_DATA,
  CREATE_COLLECTION,
  START_NEW_COMMUNITY_SEARCH,
  ATTACHMENT_CELL_UPDATED,
};
