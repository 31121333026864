<template>
  <div
    :class="{'is-edited': editMode, 'fs-12': largerTextCols.includes(col) }"
    class="wrapper textarea-wrapper d-flex align-center">
    <div
      v-if="!editMode && !isExpandedMode"
      class="d-flex justify-center"
      style="white-space: pre-line;">
      {{ formatTextAreaMessage }}
    </div>
    <div
      v-else
      :class="[
        { 'editing-container' : !isExpandedMode },
        { 'fs-12': largerTextCols.includes(col) }
      ]">
      <v-textarea
        v-model="newValue"
        :auto-grow="isExpandedMode"
        :autofocus="!isExpandedMode"
        :disabled="!editMode"
        :placeholder="hint"
        :rows="rowNumber"
        class="border-outline sourcery__filled-grey"
        filled
        hide-details
        @blur="onFocus"
        @keydown.enter.exact="onFocus" />
    </div>
  </div>
</template>
<script>
import productHeaders from '@/constants/productHeaders';
import EditModeChildComponent from '@/mixins/EditModeChildComponent';
import {
  MEDIUM,
  TALL,
} from '@/constants/heightMappingTableValues';
import { mapGetters } from 'vuex';
export default {
  name: 'ProductCellTextareaV2',
  mixins: [EditModeChildComponent],
  props: {
    isExpandedMode: {
      type: Boolean,
      default: true,
    },
    shouldExpandHeight: {
      type: Boolean,
      default: false,
    },
    cellCustomizations: {
      type: Object,
      default: () => {},
    },
  },
  data: function () {
    return {
      largerTextCols: [
        productHeaders.DESCRIPTION,
        productHeaders.PRODUCT_NOTES,
        productHeaders.PROJECT_NOTES,
        productHeaders.TIPS_AND_TRICKS,
      ],
      text: '',
      newValue: null,
      options: {
        modules: {
          toolbar: [['bold', 'italic', 'underline'], [{
            'list': 'ordered',
          }, {
            'list': 'bullet',
          }]],
        },
        placeholder: '',
        readOnly: true,
        theme: 'snow',
      },
    };
  },
  computed: {
    ...mapGetters({
      getSelectedRowHeight: 'ScheduleViews/getSelectedRowHeight',
    }),
    rowNumber() {
      if (this.cellCustomizations?.textArea?.rowNumber) {
        return this.cellCustomizations.textArea.rowNumber;
      }

      if (this.col === productHeaders.SHOP_DRAWING_COMMENTS) return 6;

      if (this.isExpandedMode & !this.shouldExpandHeight) return 1;

      const criteria = this.getSelectedRowHeight;
      return (criteria === MEDIUM || criteria === TALL) ? 6 : 3;
    },
    formatTextAreaMessage() {
      const { value } = this;
      const valueForRendering = Array.isArray(value) ? value.join(',') : value;
      return valueForRendering ? valueForRendering.toString() : '';
    },
  },
  watch: {
    value: {
      handler(val) {
        this.newValue = Array.isArray(val) ? val.join(', ') : val;
      },
      immediate: true,
    },
  },
  methods: {
    onFocus() {
      this.toggleEditMode();
      this.update();
    },
    update() {
      // Якщо тип хедера від початку array передаємо дані як масив з одним рядком , в іншому випадку просто як стрінг
      if (this.isArray) {
        this.$emit('change', {
          values: [this.newValue],
        });
      } else {
        this.$emit('change', {
          value: this.newValue,
        });
      }
    },
  },
};
</script>
<style scoped lang="scss">
.wrapper {
  min-width: 160px !important;
}
::v-deep.textarea-wrapper {
  div:not(.v-input__append-inner) {
    width: 100% !important;
    overflow: hidden;
  }
}
::v-deep textarea {
  overflow: auto !important;
}
::v-deep .v-text-field.v-input--is-dirty textarea {
  color: black !important;
}
::v-deep p {
  font-size: unset !important;
  line-height: unset !important;
  margin-bottom: 0;
}

// Used in table mode (cell pops out like in Sheets/Excel)
.editing-container {
  position: absolute;
  padding-top: 2.25rem;
  padding-bottom: 4px;

  // prevent the 'double-borders' in parent
  border: 1px solid var(--v-green-base);
  background: var(--v-lightGrey-base);
  top: -1px;
  left: -1px;
  transform: scale(1.01 );
  transform-origin: top left;
}
</style>
