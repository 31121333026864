<template>
  <div
    :class="[isOpenedInDialog && 'attachment-documents-wrapper__expanded-view', styleFilledImg ]"
    class="attachment-documents-wrapper">
    <app-dialog
      ref="dialog"
      v-model="isDialogOpen"
      :value.sync="isDialogOpen"
      width="514"
      content-class="v-dialog__form attachments-documents-wrapper"
      @click:outside="cancelDialog">
      <div
        class="dialog-content"
        @click="onFocusIn">
        <v-card @paste="onPaste">
          <v-card-title>
            <div class="d-flex align-center">
              Add attachment
              <AppFocusWarningInput />
            </div>
            <span
              class="sourcery__icon-wrapper black"
              @click="cancelDialog">
              <v-icon
                color="white"
                size="15">
                mdi-close
              </v-icon>
            </span>
          </v-card-title>
          <div>
            <AppFocusWarning :show-warning="showFocusWarning" />
            <FileUpload
              ref="upload"
              class="file-uploads"
              :input-id="rowId"
              chunk-enabled
              :value="getFileResources"
              :multiple="true"
              :drop="true"
              :drop-directory="false"
              @input-filter="inputFilter"
              @input="updateValue">
              <img
                src="@/assets/icons/dragAndDrop.png"
                alt="dragAndDrop">
              Copy and paste or drag and drop documents here or click to upload
            </FileUpload>
          </div>
          <v-card-text>
            <div
              v-if="!isEmptyFilesUrl"
              class="key-image" />
            <draggable
              tag="div"
              :list="values"
              group="image"
              filter=".files-wrapper__item__close-icon"
              class="files-wrapper">
              <div
                v-for="val in values"
                v-show="!checkIsAirtableItemToRemove(val.id) || isFileResource(val)"
                :key="`val-${val.id}`"
                class="files-wrapper__item">
                <div
                  class="file text-center">
                  <div class="image-pdf-file">
                    <CadBimFile
                      v-if="isCadBim"
                      :show-name-file="false"
                      :cad-bim-styles="{width:'50px',height :'50px'}"
                      :file="val" />
                    <div
                      v-else-if="checkedImgOrPdf(val) && val.url"
                      class="wrapper-img-pdf">
                      <ImgPdfFile
                        disabled-custom-size-pdf
                        :file="val"
                        :thumbnail-size="getSizeThumbnailForModal(val)"
                        @onPdfLoad="onPdfLoad"
                        @onPdfProgress="onPdfProgress" />
                    </div>
                    <DocumentFile
                      v-else
                      :show-name-file="false"
                      :is-size-ies-file="50"
                      :file="val"
                      @openAttachmentInNewWindow="openAttachmentInNewWindow" />
                    <div
                      class="image__filename"
                      :class="isPhotometricExt(val.filename) && 'es-file'"
                      @click="isPhotometricExt(val.filename) && openAttachmentInNewWindow(val.url)">
                      {{ val.filename }}
                    </div>
                  </div>
                </div>
                <div
                  class="files-wrapper__item__close-icon"
                  @click.stop="onFileRemove(val.id, isAwsResource(val))">
                  <v-icon
                    color="#D9DCDD">
                    mdi-close-circle
                  </v-icon>
                </div>
              </div>
            </draggable>
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn
              class="white--text"
              color="blue"
              elevation="0"
              @click="saveFile">
              Save
            </v-btn>
          </v-card-actions>
        </v-card>
      </div>
    </app-dialog>
    <v-row
      v-if="isShowImg"
      ref="blockImgs"
      :class="hasImg && 'filled-imgs'"
      :style="{gap:spacingImgs + 'px'}"
      class="ma-0 flex-nowrap image-preview">
      <div
        v-for="(img,index) in arrImgs"
        :key="index"
        class="image-preview__file d-flex">
        <CadBimFile
          v-if="isCadBim"
          :cad-bim-styles="isExpandedMode ? cadBimStyles : cadBimScheduleWrapperStyle"
          :cad-bim-img-styles="!isExpandedMode ? cadBimStyles : {}"
          :file="img"
          @download="download" />
        <ImgPdfFile
          v-else-if="checkedImgOrPdf(img)"
          :is-expanded-mode="isExpandedMode"
          :file="img"
          :transform-images-to-data="transformImagesToData"
          :thumbnail-size="currentSizeThumbnail"
          :preview-pdf-styles="previewPdfStyles"
          @setFilesPreview="setFilesPreview(img)" />
        <DocumentFile
          v-else
          :is-size-ies-file="isSizeIesFile"
          :ies-file-styles="iesFileStyles"
          :file="img"
          @openAttachmentInNewWindow="openAttachmentInNewWindow" />
        <CounterAttachment
          :class="[isExpandedMode && isMultipleImg ? 'additional-images-count--absolute' : '']"
          :count="lengthOfHidedImgs"
          :is-show-counter="showCounter(img)" />
      </div>
      <AppFileImagePreview
        :items="getDefaultFieldValues"
        @click.stop="()=>{}"
        @clearFilePreview="resetFilePreview" />
      <v-icon
        v-if="isExpandedMode"
        class="icon icon-attachment icon-attachment-filled"
        color="#979797"
        @click.stop="isDialogOpen = true">
        mdi-attachment
      </v-icon>
    </v-row>
    <p
      v-if="hasImg && isExpandedMode && !isReadOnlyModalVariant && !disabledEditMode"
      class="hint-upload-file">
      Please click
      <v-icon
        size="16px"
        class="icon icon-attachment"
        color="#979797">
        mdi-attachment
      </v-icon>
      to upload a file
    </p>
    <v-icon
      v-if="borderedCell && !disabledEditMode"
      color="blue"
      class="v-icon__custom-add-icon"
      @click="!hasAccessEditor ? $emit('messagesNoHasAccessEditor') : isDialogOpen = true">
      mdi-plus-circle
    </v-icon>
    <div
      v-else-if="showAddAttachmentBlock"
      class="add-images-wrapper">
      <div
        class="add-images-wrapper__add-images">
        <div>
          <v-icon
            class="icon icon-attachment"
            color="#979797">
            mdi-attachment
          </v-icon>
        </div>
        <div
          v-if="!isReadOnlyModalVariant && !disabledEditMode">
          <p><span class="blue--text"> Please click here </span> <span>to upload a file</span></p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {
  mapMutations, mapGetters, mapActions,
} from 'vuex';
import draggable from 'vuedraggable';
import FileUpload from 'vue-upload-component';
import { Storage } from 'aws-amplify';
import { v4 as uuid } from 'uuid';
import { cloneDeep } from 'lodash';

import AwsResource from '@/utils/files/AwsResource';
import FileProcessor from '@/utils/files/FileProcessor';
import FileResource from '@/utils/files/FileResource';
import { isPhotometricExt } from '@/utils/fileExtensions';
import {
  isIdenticalIdArr, parseStorageKey, isS3ImageUrlExpired,
} from '@/utils';

import { TYPE_SPEC_SHEET } from '@/constants';
import {
  TALL, MEDIUM, SHORT,
} from '@/constants/heightMappingTableValues';
import {
  DEFAULT_FIELD_THUMBNAILS, DEFAULT_THUMBNAILS_SIZES, THUMBNAILS_SIZE, ORIGINAL,
} from '@/constants/thumbnails';

import EditModeChild from '@/mixins/EditModeChildComponent';
import FileAttempts from '@/mixins/fileAttempts';
import fileProgressMixin from '@/mixins/fileProgressMixin';
import focusOutModal from '@/mixins/FocusOutModal';
import PasteClipboard from '@/mixins/PasteClipboard';

import API from '@/services/graphql/index';
import attachmentAPI from '@/services/graphql/subscriptions';
import updateCollectionProductField from '@/mixins/updateCollectionProductField';

import AppFileImagePreview from '@/components/App/AppFileImagePreview';
import AppFocusWarning from '@/components/App/AppFocusWarning';
import AppFocusWarningInput from '@/components/App/AppFocusWarningInput';
import CadBimFile from '@/components/Product/ProductCell/ProductCellAttachmentV2/CadBimFile';
import CounterAttachment from '@/components/Product/ProductCell/ProductCellAttachmentV2/CounterAttachment';
import DocumentFile from '@/components/Product/ProductCell/ProductCellAttachmentV2/documentFile';
import ImgPdfFile from '@/components/Product/ProductCell/ProductCellAttachmentV2/imgPdfFile';

import {
  EventBus,
  ATTACHMENT_CELL_UPDATED,
} from '@/utils/eventBus';

export default {
  name: 'ProductCellAttachmentV2',
  components: {
    AppFocusWarning,
    AppFocusWarningInput,
    CadBimFile,
    CounterAttachment,
    DocumentFile,
    draggable,
    AppFileImagePreview,
    FileUpload,
    ImgPdfFile,
  },
  mixins: [EditModeChild, PasteClipboard, fileProgressMixin, FileAttempts, updateCollectionProductField, focusOutModal],
  props: {
    isDialogCollection: {
      type: Boolean,
      default: true,
    },
    isExpandedMode: {
      type: Boolean,
      default: true,
    },
    styleFilledImg: {
      type: String,
      default: 'style1',
    },
    hasAccessEditor: {
      type: Boolean,
      default: true,
    },
    item: {
      type: Object,
      required: true,
    },
    header: {
      type: Object,
      required: true,
    },
    value: {
      type: Array,
      default: () => [],
    },
    tableId: {
      type: String,
      default: null,
    },
    borderedCell: {
      type: Boolean,
      default: false,
    },
    view: {
      type: String,
      default: '',
    },
    isOpenedInDialog: {
      type: Boolean,
      default: false,
    },
    disabledEditMode: {
      type: Boolean,
      default: false,
    },
    transformImagesToData: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    widthBlockImgs: 0,
    isDestroyed: false,
    files: [],
    airtableFilesToDelete: [],
    filesForPreview: [],
    valueIds: [],
    values: [],
    fetching: false,
    attempts: {
    },
    thumbnailsSize: {
      [TALL]: 'large',
      [MEDIUM]: 'medium',
      [SHORT]: 'small',
    },
    previewPdfStyles: {
    },
    widthCamBim: 90,
    widthIesFile: 95,
    widthIesFileMini: 70,
    widthCamBimMini: 60,
    attachmentCellUpdateHandler: null,
  }),
  computed: {
    ...mapGetters('Collections', ['updatedProduct', 'isReadOnlyModalVariant', 'isSaveAsNewVariant']),
    ...mapGetters('ScheduleViews', ['getSelectedRowHeight']),
    ...mapGetters('FeatureFlags', ['useIncreaseCaching', 'useRemoveEditWarningForFollowedProducts']),
    ...mapGetters('ManageFiles', ['getFileByKey']),
    isPhotometricCell() {
      return this.col === 'Photometric Data File';
    },
    isShowImg() {
      return this.savedImgs.some(e => e.url);
    },
    cadBimSize() {
      const { isMultipleImg, isExpandedMode, widthCamBim, widthCamBimMini } = this;
      return isMultipleImg && isExpandedMode ? widthCamBimMini : widthCamBim;
    },
    isSizeIesFile() {
      const { isMultipleImg, isExpandedMode, widthIesFile, widthIesFileMini } = this;
      return isMultipleImg && isExpandedMode ? widthIesFileMini : widthIesFile;
    },
    iesFileStyles() {
      const { isExpandedMode, isMultipleImg, widthIesFileMini, header } = this;
      let width = header?.columnSize?.size - 45 + 'px';
      if (isExpandedMode) {
        width = '100%';
        if (isMultipleImg) {
          width = widthIesFileMini + 'px';
        }
      }
      return {
        width,
      };
    },
    cadBimScheduleWrapperStyle() {
      const width = this.header?.columnSize?.size - 45 + 'px';
      return {
        width,
      };
    },
    cadBimStyles() {
      return {
        width: this.cadBimSize + 'px',
      };
    },
    isMultipleImg() {
      return this.styleFilledImg === 'style2';
    },
    spacingImgs() {
      return 10;
    },
    imgSize() {
      return DEFAULT_THUMBNAILS_SIZES()[this.getThumbnailSize];
    },
    lengthOfHidedImgs() {
      return this.savedImgs.length - this.arrImgs?.length;
    },
    arrImgs() {
      return (this.styleFilledImg === 'style1') || !this.isExpandedMode ? [this.savedImgs[0]] || [] : this.arrImgsMultiple;
    },
    hasImg() {
      return this.savedImgs.length;
    },
    savedImgs() {
      const idsNotSavedFiles = this.files.map(e => e.id);
      return this.values.filter(e => !idsNotSavedFiles.includes(e.id));
    },
    arrImgsMultiple() {
      let allowRenderingEl = 0;
      const {
        savedImgs: values,
        getThumbnailSize,
        isCadBim,
        spacingImgs,
        widthCamBimMini,
        isPhotometricCell,
        widthIesFileMini,
      } = this;
      const spacing = spacingImgs - spacingImgs;
      let widthThumbnails;
      const imgsForRendering = [];
      let spaceForImgs = this.widthBlockImgs;
      while (spaceForImgs >= 0 && values[allowRenderingEl]) {
        let newImg;
        const file = cloneDeep(values[allowRenderingEl]);
        const { filename = '' } = file || {
        };
        const imgThumbnails = file.thumbnails?.[getThumbnailSize];
        if (isCadBim) widthThumbnails = widthCamBimMini;
        else if (isPhotometricCell && isPhotometricExt(filename)) widthThumbnails = widthIesFileMini;
        else if ((!file.thumbnails || !imgThumbnails?.url)) widthThumbnails = file.width;
        else if (imgThumbnails?.url) widthThumbnails = imgThumbnails.width;
        newImg = file;
        allowRenderingEl += 1;
        spaceForImgs = spaceForImgs - (+widthThumbnails) - spacing;
        spaceForImgs >= 0 && imgsForRendering.push(newImg);
      }
      return imgsForRendering;
    },
    isEmptyFilesUrl() {
      const filterRemovedFiles = this.values.filter(e => !this.airtableFilesToDelete.includes(e?.id));
      return filterRemovedFiles?.length === 0;
    },
    getFileResources() {
      return this.values.filter(v => v?.file && FileProcessor.isFileResource(v?.file));
    },
    getAwsResources() {
      return this.values.filter(v => v && FileProcessor.isAwsResource(v));
    },
    isFullSizeImg() {
      return this.isExpandedMode && !this.isMultipleImg;
    },
    currentSizeThumbnail() {
      return !this.isFullSizeImg ? this.getThumbnailSize : '';
    },
    getThumbnailSize() {
      return THUMBNAILS_SIZE[this.getSelectedRowHeight];
    },
    showAddAttachmentBlock() {
      const { isArrayValue, savedImgs } = this;
      return this.isOpenedInDialog && isArrayValue && !savedImgs.length;
    },
    isArrayValue() {
      const { value } = this;
      return Array.isArray(value);
    },
    getDefaultFieldValues() {
      const values = this.filesForPreview.map((v) => {
        if (!v.thumbnails) {
          v.custom = true;
          v.thumbnails = DEFAULT_FIELD_THUMBNAILS(v?.url);
        }
        return v;
      });
      return values;
    },
    isExpandedViewInMobile() {
      return this.$vuetify.breakpoint.smAndDown && this.isOpenedInDialog;
    },
    isDialogOpen: {
      get() {
        return this.editMode;
      },
      set(val) {
        this.setEditMode(val);
      },
    },
    rowId() {
      return `${this.item.id} ${uuid()}`;
    },
    isCadBim() {
      return this.col === 'CAD/BIM File';
    },
  },
  watch: {
    hasImg(val) {
      if (val && this.isMultipleImg && !this.widthBlockImgs) {
        this.$nextTick().then(() => {
          this.calcWidthBlock();
        });
      }
    },
    value(newValue, oldValue) {
      !isIdenticalIdArr(newValue, oldValue) && this.getUrlForValues();
    },
    isDialogOpen(val) {
      if (val) {
        this.createFocusEvent();
        return;
      }
      if (!val) {
        this.removeFocusEvent();
        this.airtableFilesToDelete = [];
      }
    },
  },
  async created() {
    await this.getUrlForValues();
  },
  mounted() {
    this.attachmentCellUpdateHandler = (payload) => {
      this.onAttachmentsExternalUpdate(payload);
    };
    EventBus.$on(ATTACHMENT_CELL_UPDATED, this.attachmentCellUpdateHandler);
  },
  beforeDestroy() {
    this.isDestroyed = true;
    this.removeFocusEvent();
    EventBus.$off(ATTACHMENT_CELL_UPDATED, this.attachmentCellUpdateHandler);
  },
  methods: {
    ...mapMutations(['spinner']),
    ...mapMutations('Collections', ['changeProductModalVariant', 'setProductModalSpecSheetUrl']),
    ...mapMutations('Images', {
      setImageInCache: 'setImage',
    }),
    ...mapActions(['declareAttachment', 'handleError', 'downloadFileByBlob']),
    ...mapActions('ManageFiles', ['parseFile', 'removeFilesFromCache']),
    showCounter(img) {
      const { arrImgs } = this;
      if (!Array.isArray(arrImgs)) return false;
      const prevLastItem = arrImgs[arrImgs.length - 1];
      return prevLastItem.id === img.id;
    },
    /**
     * detect if file is not uploaded
     */
    isFileResource(file) {
      if (!file?.file) return;
      return FileProcessor.isFileResource(file?.file);
    },
    /**
     * detect if file is uploaded to server
     */
    isAwsResource(file) {
      return FileProcessor.isAwsResource(file);
    },
    isPhotometricExt,
    download(file) {
      const { type = '', filename = '', url = '' } = file || {
      };
      this.downloadFileByBlob({
        type,
        filename,
        url,
      });
    },
    cancelDialog() {
      this.isDialogOpen = false;
      this.values = this.getAwsResources;
    },
    checkedImgOrPdf(file) {
      const resource = new AwsResource({
        file,
      });
      return resource.getUrl()
        && (resource.isImageType() || resource.isPdfType());
    },
    async setFilesPreview(img) {
      if (this.shouldRefreshValueUrls()) {
        try {
          this.spinner(true);
          await this.getUrlForValues([], true);
        } catch (err) {
          console.log('err refreshing image urls', err);
        } finally {
          this.spinner(false);
        }
      }

      // for images open in AppFilePreview Modal
      // for pdf, if the modal is not open
      if ((img.type && img.type.includes('image')) || !this.isOpenedInDialog) {
        this.filesForPreview = this.values;
      } else {
        // for spec sheet open in same modal only
        this.changeProductModalVariant(TYPE_SPEC_SHEET);
        this.setProductModalSpecSheetUrl(img?.url);
      }
    },
    getSizeThumbnailForModal(attachment) {
      const { [MEDIUM]: tall, [TALL]: extraTall } = THUMBNAILS_SIZE;
      const { [tall]: medium } = attachment.thumbnails || {
      };
      return medium ? tall : extraTall;
    },
    inputFilter(newFile, oldFile, prevent) {
      let val;
      const fileResource = new FileResource({
        file: newFile,
      });
      const isIesFile = this.isPhotometricCell && fileResource.isPhotometricType();
      if (newFile && !oldFile && !isIesFile && !this.isCadBim) {
        val = fileResource.isImageType() || fileResource.isPdfType() ? true : prevent();
      }
      return val;
    },
    openAttachmentInNewWindow(url) {
      window.open(url, '', 'fullscreen=yes, scrollbars=auto');
    },
    async getValueByKey(v, valueIds = null, forceRefresh) {
      let types = ['image/png', 'image/gif', 'image/jpeg', 'image/webp', 'image/jpg', 'application/pdf'];
      if (forceRefresh || (!v?.key && !v?.url)) {
        v = await this.getAttachmentInfo(v?.id ?? v);
      }
      if (v?.key) {
        const { key, identityId, filename } = parseStorageKey(v.key);
        v.filename = filename;
        if (this.$route.name === 'collection-library' && !this.updatedProduct) {
          let type = v.key.split('.');
          types.forEach(element => {
            if (element.includes(type[1])) {
              v.type = element;
            }
          });
        }
        if (!v.url || valueIds.includes(v?.id)) {
          if (this.useIncreaseCaching) {
            await this.parseFile({
              key,
              config: {
                level: 'protected',
                identityId,
              },
            });
            v.url = this.getFileByKey(key);
          } else {
            v.url = await Storage.get(
              key, {
                level: 'protected',
                identityId,
              }
            );
          }
        }
        if (v.thumbnails) {
          await Promise.all(Object.keys(v.thumbnails).map(async (item) => {
            if (!v.thumbnails[item]?.url && v.thumbnails[item] || v.thumbnails[item] && valueIds.includes(v.id)) {
              const thumbItem = v.thumbnails[item];
              const { key, identityId } = parseStorageKey(thumbItem.key);
              if (this.useIncreaseCaching) {
                await this.parseFile({
                  key,
                  config: {
                    level: 'protected',
                    identityId,
                  },
                });
                thumbItem.url = this.getFileByKey(key);
              } else {
                thumbItem.url = await Storage.get(
                  key, {
                    level: 'protected',
                    identityId,
                  }
                );
              }
            }
          }));
        }
      }
      return v;
    },
    async getAttachmentInfo(id) {
      try {
        const { data } = await API.getAttachmentInfo({
          id,
        });
        return data.response;
      } catch (err) {
        console.log('err getAttachmentInfo', err);
      }
    },
    shouldRefreshValueUrls() {
      if (!this.values) return true;

      for (const item of this.values) {
        if (item.url) {
          if (isS3ImageUrlExpired(item.url)) {
            return true;
          }
        }
      }
      return false;
    },
    async getUrlForValues(valueIds = [], forceRefresh = false) {
      this.valueIds = this.value.map((value) => {
        if (value?.id) {
          return value.id;
        } else {
          return value;
        }
      });
      const valueWithImagesUrls = await Promise.all(this.value.map(async (v) => {
        return await this.getValueByKey(v, valueIds, forceRefresh);
      }));
      const files = [...this.getFileResources.filter(resource => !resource.uploadedFile), ...valueWithImagesUrls].filter(e => e?.url);
      if (files.every(e => e.key && e.url)) this.values = files;
    },
    resetFilePreview() {
      this.filesForPreview = [];
    },
    async allowToProceed() {
      const { useRemoveEditWarningForFollowedProducts: flag } = this;
      if (this.$route.name === 'collection-library' && !this.updatedProduct) {
        if (this.isOwnMode || await this.hasAccessFromOriginCollection()) {
          const response = await this.isUpdateOrCreate();
          if (!response) return false;
          else if (response === 'create') this.isCreateNewProduct = true;
        } else if (this.item.mode === 'follow' && !flag) {
          const response = await this.$openConfirm({
            text: 'By editing a followed product, you will make a copy of that product. Are you sure you want to proceed?',
          });
          if (!response) {
            return false;
          }
        }
      }
      return true;
    },
    formatFileNames(files) {
      return files.map((f)=>{
        const timestamp = Date.now();
        const filename = f.name.split('.')[0];
        const fileExt = f.name.split('.')[1];
        if (!filename.includes('-timestamp')) {
          f.filename = `${filename}_${timestamp}-timestamp.${fileExt}`;
          f.name = `${filename}_${timestamp}-timestamp.${fileExt}`;
        }
        return f;
      });
    },
    async updateValue(inpFiles) {
      try {
        let width, height;
        if (this.isExpandedViewInMobile) {
          width = 600;
          height = 600;
        } else {
          const { imgSize } = this;
          width = imgSize.width;
          height = imgSize.height;
        }
        this.files = [...this.formatFileNames(inpFiles)];
        const reducedFiles = inpFiles.filter(file => !file?.isProcessed && this.isFileResource(file));
        const filesWithThumb = await Promise.all(reducedFiles.map(async file => {
          const changedFile = new FileResource({
            file: cloneDeep(file),
          });
          let sizeRatioImg;
          if (changedFile.isImageType() && !this.isFullSizeImg) {
            sizeRatioImg = await changedFile.addResizedVersion(width, height);
          }
          const receivedFile = changedFile.getFile();
          const { resizedFile, file: fileData, name: filename } = receivedFile || {
          };
          const url = this.getUrlWithObject(resizedFile || fileData);
          const fileWithUrl = {
            ...receivedFile,
            filename,
            url,
          };
          return {
            ...fileWithUrl,
            isProcessed: true,
            ...sizeRatioImg,
          };
        }));
        this.values = [...this.values, ...filesWithThumb];
      } catch (e) {
        console.log(e);
      }
    },
    getUrlWithObject(file) {
      let path = (window.URL || window.webkitURL).createObjectURL(file);
      return path || '';
    },
    async onFileRemove(id, isAirtableImage) {
      if (isAirtableImage) {
        this.airtableFilesToDelete.push(id);
      } else {
        this.values = this.values.filter((f) => f.id !== id);
      }
    },
    checkIsAirtableItemToRemove(fileId) {
      return this.airtableFilesToDelete.find((i) => i === fileId);
    },
    async saveFile() {
      const allowToSave = await this.allowToProceed();
      if (!allowToSave) {
        return;
      }
      let keys = [];
      this.spinner(true);
      this.$emit('saveFileProcess', true);
      if (this.airtableFilesToDelete.length) {
        this.values = this.values.filter(e => !this.airtableFilesToDelete.includes(e.id));
      }
      await this.setEditMode(false);
      this.files = [];
      const { previewPdfStyles } = this;
      this.values.forEach(e => {
        const { id = '' } = e;
        if (previewPdfStyles[id]) {
          const { width = 0, height = 0 } = previewPdfStyles[id] || {
          };
          this.$set(e, 'width', parseFloat(width));
          this.$set(e, 'height', parseFloat(height));
        }
      });
      const filesForSave = cloneDeep(this.getFileResources);
      const valueIdsBeforeSave = this.getAwsResources.map(e => e.id);
      let newFilesNameKeyList = [];
      const declaredAttachments = filesForSave.map(file => {
        return new Promise(async (resolve, reject) => {
          try {
            const key = await this.declareAttachment({
              isCollection: this.isDialogCollection,
              rowId: this.item.id,
              col: this.header.value,
              tableType: 'schedule',
              schedule_id: this.tableId,
              projectId: this.$route.params.id,
              filename: file.name,
              file,
            });
            file.uploadedFile = true;
            attachmentAPI.subscribeThumbnailsUpdate({
              id: key,
            }).subscribe({
              next: ({ value }) => {
                this.setNewFile(value.data.response, file.id);
              },
            });
            keys.push(key);
            newFilesNameKeyList.push({
              key,
              ...file,
            });
            resolve();
          } catch (err) {
            this.handleError(err);
            reject();
          }
        });
      });
      await Promise.allSettled(declaredAttachments);
      const { isCreateNewProduct } = this;
      this.valueIds = [...valueIdsBeforeSave, ...keys];
      // to prevent order bug with new images
      if (newFilesNameKeyList) {
        this.values = this.updateValuesIdsWithNewFiles(newFilesNameKeyList);
        this.updateImageCache(newFilesNameKeyList);
        EventBus.$emit(ATTACHMENT_CELL_UPDATED, {
          id: this.item.id,
          col: this.col,
          mode: this.isExpandedMode,
          attachments: newFilesNameKeyList,
        });
      }
      this.$emit('change', {
        values: this.values.map(({ id }) => id),
        cellType: 'attachment',
        isCreateNewProduct,
      });
      if (this.airtableFilesToDelete.length) {
        this.removeFilesFromCache({
          keys: this.airtableFilesToDelete,
        });
      }
      this.spinner(false);
      this.$emit('saveFileProcess', false);
      this.isCreateNewProduct = false;
    },
    updateValuesIdsWithNewFiles(filesToSave) {
      return this.values.map((v) => {
        const newFileId = filesToSave.find((f)=>f.filename === v.filename);
        if (newFileId && newFileId.key) {
          v.id = newFileId.key;
        }
        return v;
      });
    },
    async onPdfLoad(file, pdfProvider) {
      if (this.previewPdfStyles[file.id]) {
        return;
      }
      const pdfResource = new FileResource({
        file,
        pdfProvider,
      });
      pdfResource.setResizedPdfStyles(this.previewPdfStyles, this.getThumbnailSize, this);
    },
    async setNewFile(value, fileId) {
      const { values } = this;
      const newFile = await this.getValueByKey(value);
      const hasFile = values.some(e => e.id === newFile.id);
      if (hasFile) {
        const indexAws = values.findIndex(e => e.id === newFile.id);
        const { width = 0, height = 0 } = values[indexAws] || {
        };
        indexAws > -1 && this.$set(this.values, indexAws, {
          ...newFile, width, height,
        });
      } else {
        const index = values.findIndex(e => e.id === fileId);
        const { width = 0, height = 0 } = values[index] || {
        };
        index > -1 && this.$set(this.values, index, {
          ...newFile, width, height,
        });
      }
    },
    calcWidthBlock() {
      const el = this.$refs.blockImgs;
      if (!el) return false;
      const computedStyle = getComputedStyle(el);
      let elementWidth = el.offsetWidth;
      elementWidth -= parseFloat(computedStyle.paddingLeft) + parseFloat(computedStyle.paddingRight);
      this.widthBlockImgs = elementWidth;
    },
    updateImageCache(files) {
      for (const f of files) {
        if (!f.key || !f.url) continue;
        this.setImageInCache({
          key: f.key,
          size: ORIGINAL,
          value: f.url,
        });
      }
    },
    // Sync uploaded/blob attachments across expanded/non-expanded faster.
    onAttachmentsExternalUpdate(payload) {
      const { id, col, mode, attachments } = payload;
      if (id != this.item.id || col != this.col) return;
      if (mode == this.isExpandedMode) return;
      this.values = attachments;
    },
  },
};
</script>
<style scoped lang="scss">
@import '@/mixins.scss';
.files-wrapper {
  &__item {
    position: relative;
    z-index: 9999;
    &__close-icon {
      cursor: pointer;
      position: absolute;
      right: -10px;
      top: -10px;
    }
  }
}
.image-pdf-file {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  .wrapper-img-pdf {
    display: flex;
    height: 58px;
    border: 1px solid #E1E2E2;
    border-radius: 5px;
    justify-content: center;
    overflow: hidden;
    ::v-deep .file-image__pdf {
      height: 100%;
      width: 100%;
      canvas {
        min-height: 58px !important;
      }
    }
  }
  img {
    width: 100%;
    height: fit-content;
    display: block;
    margin: auto;
  }
}
.icon-attachment {
  transform: rotateZ(-46deg) rotateX(180deg);
}
span.image-preview {
  &__img {
    &, ::v-deep canvas {
      max-width: 100%;
    }
  }
}
.image__filename {
  @include setLineClamp($lineAmount: 2);
}
.image-preview {
  display: flex;
  align-items: center;
  &__file {
    position: relative;
    align-items: center;
    max-width: 92%; //when you will change this value goto AppPdfRenderer and change reduceProportions
  }
  &__img {
    max-height: 150px;
    max-width: 100%;
    display: block;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
  }
  &__text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
.add-images-wrapper {
  display: none;
  p {
    cursor: context-menu;
    font-size: 12px;
    margin: 0;
  }
}
.v-card {
  .style1 {
    .image-preview {
      ::v-deep &__img {
        &, ::v-deep canvas {
          max-height: 130px;
        }
      }
    }
    .filled-imgs {
      padding: 15px;
      padding-right: 35px;
      min-height: 55px;
    }
    .add-images-wrapper {
      padding: 15px;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      &__add-images {
        text-align: center;
        .icon {
          font-size: 35px;
        }
      }
    }
  }
  .style2 {
    ::v-deep .cad-bim {
      display: flex;
      justify-content: center;
      flex-direction: column;
      p {
        text-align: inherit !important;
        margin-bottom: 0 !important;
      }
    }
    .image-preview {
      &__img {
        &, ::v-deep canvas {
          max-height: 104px;
        }
      }
    }
    .filled-imgs {
      padding: 10px 10px;
      padding-right: 35px;
      height: 124px;
    }
    .add-images-wrapper {
      padding: 0 13px;
      height: 40px;
      &__add-images {
        display: flex;
        gap: 5px;
        align-items: center;
        .icon {
          font-size: 20px;
        }
      }
    }
  }
  .icon-attachment-filled {
    display: none;
  }
  .hint-upload-file {
    cursor: context-menu;
    margin: 0 0 0 5px;
    font-size: 12px;
    color: var(--v-darkGrey-base);
  }
  .filled-imgs {
    position: relative;
    background-color: var(--v-lightGrey-base);
    .icon-attachment {
      display: block;
      position: absolute;
      right: 10px;
      bottom: 10px;
    }
  }
  .add-images-wrapper {
    background-color: var(--v-lightGrey-base);
    display: flex;
    color: var(--v-darkGrey-base);
  }
}
.es-file {
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
}
.key-image {
  position: absolute;
  left: 15px;
  z-index: 1000;
  &__close-circle {
    z-index: 2;
  }
  &:after {
    position: absolute;
    content: "Key image";
    font-size: 13px;
    font-weight: bold;
    left: calc(30px);
    top: 123px;
    width: 70px;
  }
  &:before {
    content: "";
    position: absolute;
    width: 125px;
    height: 123px;
    border: 1px solid var(--v-red-base);
    opacity: 0.7;
    border-radius: 15px;
    left: 0;
    top: 0;
    z-index: -1;
  }
}
.image-filename {
  word-break: break-all;
}
</style>
